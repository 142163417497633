<template>
  <div class="cntSuccess">
    <div class="pagamento">
      <v-icon color="red" x-large>
        mdi-alert-circle
      </v-icon>
      <p class="successPagamento">Errore nella fase di pagamento</p>
      <div style="text-align: center">
        Per maggiori informazioni contattare il numero: {{ getPhone() }} o mandare una mail all'indirizzo {{ getEmail() }}
      </div>
      <v-btn v-if="isPhone" dark color="black" style="margin-top: 30px" :to="'/'">Torna alla home</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["isPhone"],

  computed: {
    ...mapState({
      contatti: state => state.contatti
    }),
  },

  methods: {
    getContatto(type) {
      var contatto = this.contatti.find(item => item.type == type);
      return contatto.info;
    },

    getPhone() {
      return this.getContatto("phone");
    },

    getEmail() {
      return this.getContatto("mail");
    }
  }
};
</script>

<style lang="scss">
.cntSuccess {
  margin: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pagamento {
    background-color: white;
    border-radius: 12px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 50%;
    .v-icon {
      font-size: 80px !important;
    }
    .successPagamento {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.phone {
  .cntSuccess{
    .pagamento {
      width: 90vw;
    }
  }
}
</style>
